














































import axios from "axios";
import Vue from "vue";
export default Vue.extend({
  props: ["type"],
  data: () => ({
    items: [] as any,
    dialog: false,
  }),
  methods: {
    gotoDetail: function (id: string) {
      this.$router.push("/backstage/" + this.type + "/" + id);
    },
    deleteItem: function (item: any) {
      console.log("deleting " + item.name + " " + item._id);
      axios
        .post(Vue.prototype.serverAddr + "/api/delete-item", {
          _id: item._id,
          collection: this.type,
        })
        .then((response) => {
          this.dialog = false;
          this.update();
        });
    },
    update: function () {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list-all", {
          collection: this.type,
          need: "",
        })
        .then((response) => {
          this.items = response.data;
        });
    },
  },
  mounted: function () {
    console.log("getting " + this.type + " list");
    this.update();
  },
});
